import React from 'react';
import qs from 'qs';

// HOC
import withProfile from 'hocs/newWithProfile';
import CardDetail from './CardDetail';

class Brand extends React.Component {
  render() {
    const { location, profile } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    
    return (
      <CardDetail
        brandDetail={profile.cards.find(card => card.brand.id === query.brand)}
      />
    );
  }
}

export default withProfile(Brand);
